import type { BadRequestError } from '@kong/sdk-portal-js'
import type { FetchError } from 'ofetch'

// An unwrapped Portal API error response
interface PortalResponseError {
  data: BadRequestError
}

// If the request was made utilizing $fetch, it will be wrapped
type FetchPortalResponseError = FetchError<PortalResponseError>

/**
 * Parses an API error response and returns the corresponding error message.
 *
 * @param {any} error - The fetch error object representing the API error response (typically passed directly from the catch block).
 * @returns {string} The error message as a string, or an empty string if the error object is invalid.
 */
export default function parseApiError(error: PortalResponseError | FetchPortalResponseError): string {
  if (!error || !error.data) {
    return ''
  }

  const { $i18n } = useNuxtApp()
  const { invalid_parameters, detail, status, title } = (error as FetchPortalResponseError).data?.data || (error as PortalResponseError).data

  let errorMessage: string = ''

  if (status === 401) {
    if (String(title || '').toLowerCase().includes('disabled')) {
      errorMessage = $i18n.t('errors.authentication.disabled')
    } else {
      errorMessage = $i18n.t('errors.authentication.failed')
    }
  } else if (status === 503) {
    errorMessage = $i18n.t('errors.general.service_unavailable')
  } else if (status === 429) {
    errorMessage = $i18n.t('errors.general.rate_limit_exceeded')
  } else if (invalid_parameters && Array.from(invalid_parameters || []).length) {
    const { field, reason } = Array.from(invalid_parameters)[0]
    errorMessage = capitalizeFirstCharacter(field) + ' ' + reason
    // For now, replace the first word if repeated
    if (errorMessage.includes(capitalizeFirstCharacter(field) + ' ' + capitalizeFirstCharacter(field))) {
      errorMessage = errorMessage.replace(`${capitalizeFirstCharacter(field)} `, '')
    }
  } else if (detail) {
    errorMessage = detail
  } else {
    errorMessage = `${$i18n.t('errors.error')} ${status}: ${title}`
  }

  // TODO: Special checks for odd error messages; should tweak parsing logic to handle these cases

  if (errorMessage.toLowerCase().startsWith('token email')) {
    // Change evaulated message from "Token email verification token is invalid" to "Email verification token is invalid"
    errorMessage = capitalizeFirstCharacter(errorMessage.replace(/^token /i, ''))
  }

  return errorMessage
}
